import { ThemeProvider } from 'react-jss';
import React from 'react';

import './src/styles/global.css';

// Based off https://github.com/gatsbyjs/gatsby/blob/master/packages/gatsby-plugin-jss/src/gatsby-browser.js

// Remove the JSS style tag generated on the server to avoid conflicts with the one added on the client
const onInitialClientRender = () => {
  const ssStyles = window.document.getElementById('server-side-jss');
  if (ssStyles) {
    ssStyles.parentNode.removeChild(ssStyles);
  }
};

// eslint-disable-next-line react/prop-types
const wrapRootElement = ({ element }, options) => {
  const theme = options.theme || {};
  return <ThemeProvider theme={theme}>{element}</ThemeProvider>;
};

export {
  onInitialClientRender,
  wrapRootElement,
};
