module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-jss/gatsby-browser.js'),
      options: {"plugins":[],"theme":{"colorPrimary":"#1B5E20","colorPrimaryFaded":"rgba(27, 94, 32, 0.25)","colorSecondary":"#F4F617","fontSizeSmall":"12px","fontSizeMedium":"16px","fontSizeLarge":"22px"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-LWR19XQQN3","UA-64138960-1"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
